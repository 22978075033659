import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/css/main.css'
import '@/assets/css/res.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import axios from 'axios'
import VueAxios from 'vue-axios'

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
// 全局定义echarts
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
//import 'echarts-liquidfill'
// import 'echarts-gl'
Vue.component('v-chart', ECharts)

// import websocket from './websocket'
// Vue.prototype.$ws = websocket

import VueAMap from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'

Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
  key: '161d326fcc4a717a0763eba4feb06551',
});

// 引入 video.js
import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;

// 加载动画
import 'vue-simple-spinner/dist/vue-simple-spinner'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// 从 0 到指定数字 xxxx
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// dataV-Vue
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    'zh': require('@/assets/lang/zh'),
    'mon': require('@/assets/lang/mon'),
    'en': require('@/assets/lang/en'),
    'jp': require('@/assets/lang/jp'),
    'cy': require('@/assets/lang/cy'),
  },
   silentTranslationWarn: true, // 去除国际化警告
})

// import mixins from '@/mixins'
// Vue.mixin(mixins)

import Bus from '@/assets/js/Bus'
Vue.prototype.$bus = Bus

// webSocket 全局监听呼叫
Vue.prototype.$webSctCall = new Vue()

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import VueCropper from 'vue-cropper'

Vue.config.productionTip = false

import BaiduMap from 'vue-baidu-map'

Vue.use(ElementUI)
Vue.use(VueAxios, axios)
Vue.use(VueCropper)
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'ouq64j60c0bS4GQVnKYaGBMBzG8bQESV'
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
