import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home')
  },
  // 菜单页
  {
    path: '/menuPage',
    component: () => import('@/views/menuPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 登录页
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
    // 防疫呼叫
  {
    path: '/fangYiHuJiao',
    component: () => import('@/views/fang-yi/hujiao/index2'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },

  // ****************** 防疫相关 ******************
  // 站点页
  {
    path: '/zhanDianPage/:id/:name/:headId',
    component: () => import('@/views/fang-yi/zhanDianPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 疫苗列表页
  {
    path: '/yiMiaoPage',
    component: () => import('@/views/fang-yi/yiMiaoPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 疫苗页
  {
    path: '/yiMiaoDetailPage/:id/:name/:typeId',
    component: () => import('@/views/fang-yi/yiMiaoDetailPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 防疫员列表
  {
    path: '/fangYiYuanList',
    component: () => import('@/views/fang-yi/fangYiYuanList'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 牧户列表
  {
    path: '/muHuList',
    component: () => import('@/views/fang-yi/muHuList'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 防疫员 详情页
  {
    path: '/fangYiYuanPage/:val',
    component: () => import('@/views/fang-yi/fangYiYuanPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 防疫员 - 防疫签到轨迹查看
  {
    path: '/fangYiGuiJi/:userId',
    component: () => import('@/views/fang-yi/fangYiGuiJi'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 动物 防疫 列表
  {
    path: '/animalFangYiList/:userId/:userName/:animalId',
    component: () => import('@/views/fang-yi/animalFangYiList'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 巴林右旗 XXX镇 防疫统计
  {
    path: '/townFyPage/:id/:level/:name',
    component: () => import('@/views/fang-yi/townPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 巴林右旗 XXX镇 XXX村 防疫统计
  {
    path: '/villageFyPage/:townId/:townName/:level/:villageId/:villageName/:isGroup',
    component: () => import('@/views/fang-yi/villagePage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 巴林右旗 XXX镇 XXX村 XXX小组 防疫统计
  {
    path: '/groupFyPage/:townId/:townName/:villageId/:villageName/:level/:groupId/:groupName',
    component: () => import('@/views/fang-yi/groupPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
  // 巴林右旗 XXX镇 XXX村 XXX小组 XXX牧户 防疫统计
  {
    path: '/muHuPage/:townId/:villageId/:groupId/:ownerId',
    component: () => import('@/views/fang-yi/muHuPage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },

  // 巴林右旗 动物免疫台账 搜索
  {
    path: '/mianYiTaiZhang/:userName',
    component: () => import('@/views/fang-yi/mianYiTaiZhang'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },


  // 巴林右旗 仓库
  {
    path: '/storePage',
    component: () => import('@/views/fang-yi/storePage'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },

  // erp 中心 、防疫站
  {
    path: '/erpSite/:storeId/:storeName/:headId',
    component: () => import('@/views/fang-yi/erpSite'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },

  // erp 防疫员
  {
    path: '/erpPerson/:storeId/:storeName/:userId',
    component: () => import('@/views/fang-yi/erpPerson'),
    hidden: true,
    meta: {
      requireAuth: true
    }
  },
]

const router = new VueRouter({
  // mode: 'hash',
  routes
})

export default router
