<template>
  <div class="full relative">
    <div class="w_full h_full-40">
      <router-view/>
    </div>
    
    <div class="w_full h_40 relative">
      <div class="w_300 h_40 lh_40 flex_row_start absolute_center">
        <div class="w_30 h_30">
          <img class="full" src="./assets//images/icon/bei-an.png" alt="">
        </div>

        <div class="w_full-30 h_30 ml_10">
          蒙公网安备15010502002216号
        </div>
      </div>
    </div>

    <!-- 全局监听呼叫 -->
    <el-dialog 
      :title="callType == 0 ? '呼叫中...' : '收到呼叫...'" 
      :visible.sync="openCallDialog"
      width="300px"
      class="callerDialog" :close-on-click-modal="false"
    >
      <audio :src="openCallDialog ? require('@/assets/mp3/call.mp3') : ''" autoplay loop></audio>

      <div v-if="false">剩余：{{ callLeftSeconds }}秒</div>

      <div v-if="caller != null">
        <div class="caller_name"> {{ caller.name }} </div>
        <el-image 
          :src="'https://animalep.5mus.com' + caller.avatar"
          style="height: 180px; width: 100%;background-color: aliceblue;" 
          fit="contain">
        </el-image>
      </div>

      <div class="myfooter">
        <div class="call_button bg_green" v-if="callType == 1" @click="answerCallApp">接听</div>
        <div class="call_button bg_red" @click="hangUpCall">挂断</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ws2 from "@/util/websocket2"
import { ZegoExpressEngine } from 'zego-express-engine-webrtc'

export default {
  data() {
    return {
      appId: 36758403,
      server: "wss://webliveroom36758403-api.imzego.com/ws",
      openCallDialog: false,
      callType: 0,
      callDialogTimerObject: null,
      callLeftSecondsTotal: 60,
      callLeftSeconds: 0,
      remoteRoomId: null,
      caller: null,

      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc1JlZnJlc2giOmZhbHNlLCJpZCI6MTMsImlhdCI6MTcyNTAwNzcyNywiZXhwIjoxNzMyNzgzNzI3fQ._y9dM2TxF2aXvkJqpyoBb7nGCoc23SXA4YDkqjHdzE8",

      webSctMsg: null,
      webSctState: null,
      zg: null,
    }
  },
  async created() {
    await this.enumDevices();

    // 链接 webSocket
    this.watchWebSocketCall() 
  },
  mounted() {

  },
  methods: {
    //拒绝呼叫
    hangUpCall() {
      this.openCallDialog = false

      // 向 webSocket 发送【挂断】信息
    },

    // 接听呼叫
    answerCallApp() {
      this.openCallDialog = false

      // 跳转到 大屏呼叫中心 页面， 进行通话
      this.$webSctCall.$emit('webSctCall', this.webSctMsg)

      // 跳转到 大屏呼叫页
      if (this.$route.path !== '/fangYiHuJiao') {
        this.$router.push('/fangYiHuJiao')
      }
    },

    // webSocket 全局监听呼叫大屏
    watchWebSocketCall() {
      ws2.init(
        // "ws://1.183.3.222:8102/ws",
        "wss://animalep.5mus.com/ws",
        this.token,
        data => {
          // console.log('websocket 全局监听 : ', data);
          try {
            var msg = JSON.parse(data)

            this.webSctMsg = msg

            // 将 msg 保存到 vuex
            this.$store.commit('setWebSocketCallMsg', msg)

            if (msg.topic === "CALL") {
              if (this.openCallDialog === false) {
                this.caller = msg.from;
                this.callType = 1;
                this.openCallDialog = true;

                this.callLeftSeconds = this.callLeftSecondsTotal;

                var timer = setInterval(() => {
                  if (this.callLeftSeconds === 0) {
                    timer && clearInterval(timer);
                  } else {
                    this.callLeftSeconds--;
                  }
                }, 1000)

                this.callDialogTimerObject && clearTimeout(this.callDialogTimerObject);

                this.callDialogTimerObject = setTimeout(() => {
                  // 60秒后关闭对话框
                  this.openCallDialog = false;
                }, 1000 * this.callLeftSecondsTotal)
              }
            }
          } catch (err) {
            this.$message.error(err)
          }
        },
        state => {
          console.log("state changed !");

          this.webSctState = state

          // 将 state 保存到 vuex
          this.$store.commit('setWebSocketCallState', state)
        }
      )

      window.sessionStorage.setItem('sock', ws2.wsObj)
    },

    // 获取 设备权限
    async enumDevices() {
      // debugger
      const appID = this.appId;
      const server = this.server + ""; //"wss://webliveroom1535963376-api.imzego.com/ws";
      this.zg = new ZegoExpressEngine(appID, server);
      window.zg = this.zg;

      const audioInputList = [],
        videoInputList = [];

      const deviceInfo = await this.zg.enumDevices();

      deviceInfo &&
        deviceInfo.microphones.map((item, index) => {
          if (!item.deviceName) {
            item.deviceName = 'microphone' + index;
          }
          // audioInputList.push(' <option value="' + item.deviceID + '">' + item.deviceName + '</option>');
          audioInputList.push(item);
          console.log('microphone: ' + item.deviceName);
          return item;
        });

      deviceInfo &&
        deviceInfo.cameras.map((item, index) => {
          if (!item.deviceName) {
            item.deviceName = 'camera' + index;
          }
          // videoInputList.push(' <option value="' + item.deviceID + '">' + item.deviceName + '</option>');
          videoInputList.push(item);
          console.log('camera: ' + item.deviceName);
          return item;
        });

      /* 
        将 
          audioInputList、
          videoInputList 、
          audioInputList[0].deviceID、
          videoInputList[0].deviceID
        保存到 vuex 
      */
      this.$store.commit('setAudioInputList', audioInputList)
      this.$store.commit('setVideoInputList', videoInputList)
      this.$store.commit('setMicrophoneDeviceVal', audioInputList[0].deviceID)
      this.$store.commit('setCameraDeviceVal', videoInputList[0].deviceID)
    },
  }
}
</script>

<style lang="less" scoped>
  .caller_name {
    text-align: center;
    height: 40px;
    list-style: 40px;
    font-size: x-large;
  }

  .myfooter {
    display: flex;
    justify-content: space-around;
    margin: 20px;

    .call_button {
      width: 50px;
      height: 50px;
      background-color: red;
      border-radius: 50%;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
      border: 10px black;
      // box-shadow: 1px 1px 1px black;
    }

    .call_button:hover {
      box-shadow: 2px 2px 4px #402222;
    }

    .call_button:active {
      box-shadow: inset 0px -1px 4px 0px #0000003d;
    }

    .bg_red {
      background-color: red;
    }

    .bg_green {
      background-color: rgb(27, 193, 27);
    }
  }
</style>

<style lang="less">
  .callerDialog {
    .el-dialog__body {
      padding: 5px;
    }
  }
</style>
