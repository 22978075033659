import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    webSocketCallMsg: null,
    webSocketCallState: null,

    audioInputList: [],
    videoInputList: [],
    microphoneDeviceVal: null,
    cameraDeviceVal: null,

    jobId: null,
    jobList: [],

    panDianId: null,
    panDianList: []
  },
  mutations: {
    // webSocket 相关
    setToken(state, val) {
      state.token = val
    },
    setWebSocketCallMsg(state, val) {
      state.webSocketCallMsg = val
    },
    setWebSocketCallState(state, val) {
      state.webSocketCallState = val
    },
    setAudioInputList(state, val) {
      state.audioInputList = val
    },
    setVideoInputList(state, val) {
      state.videoInputList = val
    },
    setMicrophoneDeviceVal(state, val) {
      state.microphoneDeviceVal = val
    },
    setCameraDeviceVal(state, val) {
      state.cameraDeviceVal = val
    },


    // 防疫工作
    setJobId(state, val) {
      state.jobId = val
    },
    setJobList(state, val) {
      state.jobList = val
    },

    // 盘点 工作
    setPanDianId(state, val) {
      state.panDianId = val
    },
    setPanDianList(state, val) {
      state.panDianList = val
    },
  },
  actions: {
  },
  modules: {
  }
})
