export default {
  DEBUG_FLAG: true,
  wsObj: null,
  wsUri: null,
  userId: -1,
  lockReconnect: false,//避免重复连,
  wsCreateHandler: null,
  pingData: {
    wsMessageType: 0,
    from: "token",
    data: "ping"
  },
  messageCallback: null,
  stateChangeCallback: null,
  token: null,

  init(wsUri, token, onMessageCallback, onStateChangeCallback) {
    this.wsUri = wsUri;
    this.token = token;
    this.messageCallback = onMessageCallback;
    this.stateChangeCallback = onStateChangeCallback;
    this.pingData.from = token;
    
    this.createWebSocket();
  },

  state: 0, // 0 

  createWebSocket() {
    // var host = window.location.host; // 带有端口号
    // userId = GetQueryString("userId");
    // // wsUri = "ws://" + host + "/websocket?userId=" + userId;
    // wsUri = "ws://" + host + "/websocket/" + userId;
    // this.wsUri = wsUri;
    // this.token = token;
    // debugger
    try {
      this.writeToScreen("连接 " + this.wsUri);
      this.wsObj = new WebSocket(this.wsUri, this.token);
      this.stateChangeCallback(this.wsObj.readyState);
      this.initWsEventHandle();
    } catch (e) {
      this.stateChangeCallback(this.wsObj.readyState);
      this.writeToScreen("连接失败");
    }
  },

  initWsEventHandle() {
    // debugger
    var self = this
    try {
      self.wsObj.onopen = function (evt) {
        self.heartCheckStart();
        self.onWsOpen(evt);
        self.stateChangeCallback(self.wsObj.readyState);
      };

      self.wsObj.onmessage = function (evt) {
        self.heartCheckStart();
        try {
          var msg = JSON.parse(evt.data)
          if (msg.data === "pong") {
            self.writeToScreen("心跳: 接收到pong消息")
          } else {
            self.onWsMessage(evt.data);
          }
        } catch (error) {
          self.writeToScreen("收到消息, " + evt.data)
        }
      };

      self.wsObj.onclose = function (evt) {
        // self.writeToScreen("执行关闭事件，开始重连");
        self.stateChangeCallback(self.wsObj.readyState);
        self.onWsClose(evt);
        self.reconnect();
      };
      self.wsObj.onerror = function (evt) {
        // self.writeToScreen("执行error事件，开始重连");
        self.stateChangeCallback(self.wsObj.readyState);
        self.onWsError(evt);
        self.reconnect();
      };
    } catch (e) {
      self.writeToScreen("绑定事件没有成功");
      self.reconnect();
    }
  },

  onWsOpen(evt) {
    this.writeToScreen("已连接");
  },

  onWsClose(evt) {
    this.writeToScreen("连接已断开");
  },

  onWsError(evt) {
    console.error("websocket: error");
    // this.writeToScreen(evt.data);
  },

  onWsMessage(data) {
    console.log("websocket: 收到消息", data);
    this.messageCallback(data);
  },

  writeToScreen(message) {
    if (this.DEBUG_FLAG) {
      // $("#debuggerInfo").val($("#debuggerInfo").val() + "\n" + message);
      console.log("websocket:", message);
    }
  },

  reconnect() {
    var self = this;
    if (this.lockReconnect) {
      return;
    };
    this.writeToScreen("1秒后重连");
    this.lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    this.wsCreateHandler && clearTimeout(this.wsCreateHandler);
    this.wsCreateHandler = setTimeout(function () {
      self.writeToScreen("重连...");
      self.createWebSocket();
      self.lockReconnect = false;
      // self.writeToScreen("重连完成");
    }, 1000);
  },

  // heartCheck: {
  //15s之内如果没有收到后台的消息，则认为是连接断开了，需要再次连接
  timeout: 30000,
  timeoutObj: null, // 发送心跳包定时器
  serverTimeoutObj: null, // 等待服务器响应定时器

  //重启
  reset: function () {
    clearTimeout(this.timeoutObj);
    clearTimeout(this.serverTimeoutObj);
    this.start();
  },

  sendMessage(msg) {
    // JSON.stringify(msg);
    try {
      console.log(msg);
      if (typeof msg === "string") {
        this.wsObj.send(msg);
      }else{
        var jsonString = JSON.stringify(msg)
        this.wsObj.send(jsonString);
      }
      this.heartCheckStart();
      
    } catch (error) {
      console.error(error);
    }
  },

  //开启定时器, 延时发送心跳包
  heartCheckStart() {
    // debugger
    var self = this;

    //清除之前的定时器
    this.timeoutObj && clearTimeout(this.timeoutObj);
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);

    this.timeoutObj = setTimeout(function () {
      self.writeToScreen("心跳: 发送ping消息");
      try {
        var stringJson = JSON.stringify(self.pingData)
        self.wsObj.send(stringJson);
      }
      catch (ee) {
        self.writeToScreen("心跳: 发送ping异常");
      }
      //内嵌计时器
      self.serverTimeoutObj = setTimeout(function () {
        //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
        self.writeToScreen("没有收到后台的数据，关闭连接");
        self.wsObj.close();
        // reconnect();
      }, self.timeout);

    }, this.timeout)
  },
}